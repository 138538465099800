import * as THREE from 'three'

// Colors
const starsColor = 0xffffff

// Materials
var starMaterial = new THREE.MeshBasicMaterial( {color: starsColor} )

// Geometry
const boxSize = 0.1
const boxGeometry = new THREE.BoxGeometry(boxSize, boxSize, boxSize)
const starBox = new THREE.Mesh(boxGeometry, starMaterial)

/**
 * Stars Generator
 * 
 * Generate stars on a plane vector
 */
export default function starsGenerator() {
	const stars = new THREE.Group()
	
	for (let i = 0; i < 500; ++i) {
		const dust = starBox.clone()
		const range = 250
		
		dust.position.x = Math.floor(Math.random() * range * 2) - range
		dust.position.y = Math.floor(Math.random() * range) - range / 2
		dust.position.z = -100
		
		stars.add(dust)
	}

	return stars
}