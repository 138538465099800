// FRONT
export const frontFace = [
	[ 0, 0, 0, 0, 0, 1, 1, 2, 1, 1, 1, 0, 0, 0, 0, 0 ],
	[ 0, 0, 0, 1, 1, 1, 1, 2, 1, 1, 1, 1, 1, 0, 0, 0 ],
	[ 0, 0, 1, 1, 2, 1, 1, 1, 1, 1, 2, 1, 1, 1, 0, 0 ],
	[ 0, 1, 1, 2, 1, 1, 1, 2, 1, 1, 2, 1, 2, 1, 1, 0 ],
	[ 0, 1, 1, 2, 1, 1, 1, 1, 2, 1, 1, 1, 2, 1, 1, 0 ],
	[ 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 1, 1, 1 ],
	[ 2, 2, 1, 1, 2, 1, 1, 2, 2, 1, 1, 1, 1, 1, 1, 1 ],
	[ 1, 2, 1, 1, 1, 1, 2, 1, 1, 1, 1, 1, 1, 1, 2, 1 ],
	[ 1, 1, 1, 1, 1, 1, 1, 1, 2, 1, 1, 2, 2, 1, 2, 2 ],
	[ 2, 1, 1, 2, 2, 1, 1, 1, 2, 1, 1, 1, 1, 2, 1, 2 ],
	[ 1, 1, 1, 1, 2, 1, 1, 1, 1, 2, 2, 1, 2, 1, 1, 2 ],
	[ 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 2, 2, 0 ],
	[ 0, 1, 1, 1, 1, 2, 1, 1, 2, 1, 2, 2, 1, 1, 2, 0 ],
	[ 0, 0, 1, 1, 1, 1, 2, 1, 1, 2, 1, 2, 1, 2, 0, 0 ],
	[ 0, 0, 0, 2, 1, 2, 1, 1, 1, 2, 1, 2, 2, 0, 0, 0 ],
	[ 0, 0, 0, 0, 0, 1, 2, 1, 1, 2, 2, 0, 0, 0, 0, 0 ]
]

// LEFT
export const leftFace = [
	[ 0, 0, 0, 0, 0, 1, 1, 2, 1, 1, 1, 0, 0, 0, 0, 0 ],
	[ 0, 0, 0, 1, 1, 1, 1, 2, 1, 1, 1, 1, 1, 0, 0, 0 ],
	[ 0, 0, 1, 1, 2, 1, 1, 1, 1, 1, 2, 1, 1, 1, 0, 0 ],
	[ 0, 1, 1, 2, 1, 1, 1, 2, 1, 1, 2, 1, 2, 1, 1, 0 ],
	[ 0, 1, 1, 2, 1, 1, 1, 1, 2, 1, 1, 1, 2, 1, 1, 0 ],
	[ 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 1, 1, 1 ],
	[ 2, 2, 1, 1, 2, 1, 1, 2, 2, 1, 1, 1, 1, 2, 2, 2 ],
	[ 1, 2, 1, 1, 1, 1, 2, 1, 1, 1, 1, 1, 2, 2, 1, 1 ],
	[ 1, 1, 1, 1, 1, 1, 1, 1, 2, 1, 1, 1, 2, 2, 2, 2 ],
	[ 2, 1, 1, 2, 2, 1, 1, 1, 2, 1, 1, 1, 1, 2, 1, 2 ],
	[ 1, 1, 1, 1, 2, 1, 1, 1, 1, 1, 1, 1, 2, 1, 1, 2 ],
	[ 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 2, 2, 0 ],
	[ 0, 1, 1, 1, 1, 2, 1, 1, 1, 1, 2, 2, 1, 1, 2, 0 ],
	[ 0, 0, 1, 1, 1, 1, 1, 1, 2, 2, 1, 2, 1, 2, 0, 0 ],
	[ 0, 0, 0, 2, 1, 2, 2, 1, 1, 2, 1, 2, 2, 0, 0, 0 ],
	[ 0, 0, 0, 0, 0, 1, 2, 1, 1, 2, 2, 0, 0, 0, 0, 0 ],
]

// RIGHT
export const rightFace = [
	[ 0, 0, 0, 0, 0, 1, 1, 2, 1, 1, 1, 0, 0, 0, 0, 0 ],
	[ 0, 0, 0, 1, 1, 1, 1, 2, 1, 1, 1, 1, 1, 0, 0, 0 ],
	[ 0, 0, 1, 1, 2, 1, 1, 1, 1, 1, 2, 1, 1, 1, 0, 0 ],
	[ 0, 1, 1, 2, 1, 1, 1, 2, 1, 1, 2, 1, 2, 1, 1, 0 ],
	[ 0, 1, 1, 2, 1, 1, 1, 1, 2, 1, 1, 1, 2, 1, 1, 0 ],
	[ 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 1, 1, 1 ],
	[ 2, 2, 1, 1, 2, 1, 1, 2, 2, 1, 1, 1, 1, 1, 1, 1 ],
	[ 1, 2, 1, 1, 1, 1, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1 ],
	[ 1, 1, 1, 1, 1, 1, 1, 1, 2, 1, 1, 2, 1, 1, 2, 2 ],
	[ 2, 1, 1, 2, 2, 1, 1, 1, 2, 1, 1, 1, 1, 2, 1, 2 ],
	[ 1, 1, 1, 1, 2, 1, 1, 1, 1, 1, 1, 1, 2, 1, 1, 2 ],
	[ 0, 1, 1, 1, 1, 1, 1, 1, 1, 2, 1, 1, 2, 2, 2, 0 ],
	[ 0, 1, 1, 1, 1, 2, 1, 1, 1, 2, 2, 2, 1, 1, 2, 0 ],
	[ 0, 0, 1, 1, 1, 1, 1, 2, 1, 2, 1, 2, 1, 2, 0, 0 ],
	[ 0, 0, 0, 2, 1, 1, 1, 1, 1, 2, 1, 2, 2, 0, 0, 0 ],
	[ 0, 0, 0, 0, 0, 1, 1, 1, 1, 2, 2, 0, 0, 0, 0, 0 ],
]

// TOP
export const topFace = [
	[ 0, 0, 0, 0, 0, 1, 1, 2, 1, 1, 1, 0, 0, 0, 0, 0 ],
	[ 0, 0, 0, 1, 1, 1, 1, 2, 1, 1, 1, 1, 1, 0, 0, 0 ],
	[ 0, 0, 1, 1, 2, 1, 1, 1, 1, 1, 2, 1, 1, 1, 0, 0 ],
	[ 0, 1, 1, 2, 1, 1, 1, 2, 1, 1, 2, 1, 2, 1, 1, 0 ],
	[ 0, 1, 1, 2, 1, 1, 1, 1, 2, 1, 1, 1, 2, 1, 1, 0 ],
	[ 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 1, 1, 1 ],
	[ 2, 2, 1, 1, 2, 1, 1, 2, 2, 1, 1, 1, 1, 1, 2, 1 ],
	[ 1, 2, 1, 1, 1, 1, 2, 1, 1, 1, 1, 1, 1, 1, 2, 1 ],
	[ 1, 1, 1, 1, 1, 1, 1, 1, 2, 2, 1, 1, 1, 1, 2, 2 ],
	[ 2, 1, 1, 2, 2, 1, 1, 1, 2, 2, 1, 1, 1, 2, 1, 2 ],
	[ 1, 1, 1, 1, 2, 1, 1, 1, 1, 1, 1, 1, 2, 1, 1, 2 ],
	[ 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 2, 2, 0 ],
	[ 0, 1, 1, 1, 1, 2, 1, 1, 2, 2, 2, 2, 1, 1, 2, 0 ],
	[ 0, 0, 1, 1, 1, 1, 1, 2, 2, 2, 1, 2, 1, 2, 0, 0 ],
	[ 0, 0, 0, 2, 1, 1, 1, 1, 1, 2, 1, 2, 2, 0, 0, 0 ],
	[ 0, 0, 0, 0, 0, 1, 1, 1, 1, 2, 2, 0, 0, 0, 0, 0 ],
]

// BOTTOM
export const bottomFace = [
	[ 0, 0, 0, 0, 0, 1, 1, 2, 1, 1, 1, 0, 0, 0, 0, 0 ],
	[ 0, 0, 0, 1, 1, 1, 1, 2, 1, 1, 1, 1, 1, 0, 0, 0 ],
	[ 0, 0, 1, 1, 2, 1, 1, 1, 1, 1, 2, 1, 1, 1, 0, 0 ],
	[ 0, 1, 1, 2, 1, 1, 1, 2, 1, 1, 2, 1, 2, 1, 1, 0 ],
	[ 0, 1, 1, 2, 1, 1, 1, 1, 2, 1, 1, 1, 2, 1, 1, 0 ],
	[ 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 1, 1, 1 ],
	[ 2, 2, 1, 1, 2, 1, 1, 2, 2, 1, 1, 1, 1, 1, 1, 1 ],
	[ 1, 2, 1, 1, 1, 1, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1 ],
	[ 1, 1, 1, 1, 1, 1, 1, 1, 2, 1, 1, 1, 1, 1, 2, 2 ],
	[ 2, 1, 1, 2, 2, 1, 1, 1, 2, 1, 1, 1, 1, 2, 1, 2 ],
	[ 1, 1, 1, 1, 2, 1, 1, 1, 1, 1, 1, 1, 2, 1, 1, 2 ],
	[ 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 2, 2, 0 ],
	[ 0, 1, 1, 1, 1, 2, 1, 1, 1, 1, 2, 2, 1, 1, 2, 0 ],
	[ 0, 0, 1, 1, 1, 1, 1, 1, 1, 2, 1, 2, 1, 2, 0, 0 ],
	[ 0, 0, 0, 2, 1, 1, 1, 1, 1, 2, 1, 2, 2, 0, 0, 0 ],
	[ 0, 0, 0, 0, 0, 2, 2, 1, 1, 2, 2, 0, 0, 0, 0, 0 ],
]

// BACK
export const backFace = [
	[ 0, 0, 0, 0, 0, 1, 1, 2, 1, 1, 1, 0, 0, 0, 0, 0 ],
	[ 0, 0, 0, 1, 1, 1, 1, 2, 1, 1, 1, 1, 1, 0, 0, 0 ],
	[ 0, 0, 1, 1, 2, 1, 1, 1, 1, 1, 2, 1, 1, 1, 0, 0 ],
	[ 0, 1, 1, 2, 1, 1, 1, 2, 1, 1, 2, 1, 2, 1, 1, 0 ],
	[ 0, 1, 1, 2, 1, 1, 1, 1, 2, 1, 1, 1, 2, 1, 1, 0 ],
	[ 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 1, 2, 2 ],
	[ 2, 2, 1, 1, 2, 1, 1, 2, 2, 1, 1, 1, 1, 2, 2, 1 ],
	[ 1, 2, 1, 1, 1, 1, 2, 1, 1, 1, 1, 1, 1, 1, 2, 1 ],
	[ 1, 1, 1, 1, 1, 1, 1, 1, 2, 1, 1, 2, 1, 1, 2, 2 ],
	[ 2, 1, 1, 2, 2, 1, 1, 1, 2, 1, 1, 1, 1, 2, 1, 2 ],
	[ 1, 1, 1, 1, 2, 1, 1, 1, 1, 1, 2, 2, 2, 1, 1, 2 ],
	[ 0, 1, 1, 1, 1, 1, 1, 1, 2, 2, 1, 1, 2, 2, 2, 0 ],
	[ 0, 1, 1, 1, 1, 2, 1, 1, 1, 1, 2, 2, 1, 1, 2, 0 ],
	[ 0, 0, 1, 1, 1, 1, 1, 1, 1, 2, 1, 2, 1, 2, 0, 0 ],
	[ 0, 0, 0, 2, 1, 2, 2, 2, 1, 2, 1, 2, 2, 0, 0, 0 ],
	[ 0, 0, 0, 0, 0, 1, 2, 1, 1, 2, 2, 0, 0, 0, 0, 0 ],
]

// DEEP
export const deepFace = [
	[ 0, 0, 0, 0, 0, 3, 3, 3, 3, 3, 3, 0, 0, 0, 0, 0 ],
	[ 0, 0, 0, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 0, 0, 0 ],
	[ 0, 0, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 0, 0 ],
	[ 0, 5, 6, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 6, 5, 0 ],
	[ 0, 5, 6, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 6, 5, 0 ],
	[ 3, 5, 6, 7, 7, 8, 8, 8, 8, 8, 8, 7, 7, 6, 5, 3 ],
	[ 3, 5, 6, 7, 7, 8, 8, 8, 8, 8, 8, 7, 7, 6, 5, 3 ],
	[ 3, 5, 6, 7, 7, 8, 8, 8, 8, 8, 8, 7, 7, 6, 5, 3 ],
	[ 3, 5, 6, 7, 7, 8, 8, 8, 8, 8, 8, 7, 7, 6, 5, 3 ],
	[ 3, 5, 6, 7, 7, 8, 8, 8, 8, 8, 8, 7, 7, 6, 5, 3 ],
	[ 3, 5, 6, 7, 7, 8, 8, 8, 8, 8, 8, 7, 7, 6, 5, 3 ],
	[ 0, 5, 6, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 6, 5, 0 ],
	[ 0, 5, 6, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 6, 5, 0 ],
	[ 0, 0, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 0, 0 ],
	[ 0, 0, 0, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 0, 0, 0 ],
	[ 0, 0, 0, 0, 0, 3, 3, 3, 3, 3, 3, 0, 0, 0, 0, 0 ],
]