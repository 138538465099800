import * as THREE from 'three'

// Colors
const ringColor = 0x6e593f

// Materials
const ringMaterial = new THREE.MeshBasicMaterial( {color: ringColor} )

// Geometry
const boxSize = 0.1
const boxGeometry = new THREE.BoxGeometry(boxSize, boxSize, boxSize)
const ringBox = new THREE.Mesh( boxGeometry, ringMaterial)

/**
 * Ring Generator
 * 
 * Generate rings
 * 
 * @param {float} min Minimal radius
 * @param {float} max Maximal radius
 * @param {int} quantity Quantity of stars
 */
export default function ringGenerator(min, max, quantity) {
	const ring = new THREE.Group()

	for (let range = min; range <= max; range += 0.8) {
		for (let i = 0; i < quantity; ++i) {
			var dust = ringBox.clone()

			const r1 = range
			const r2 = range + 0.5
			const r = Math.sqrt(Math.pow(r1, 2) + (Math.pow(r2, 2) - Math.pow(r1, 2)) * Math.random())
			const t = 2 * Math.PI * Math.random()

			dust.position.x = r * Math.cos(t)
			dust.position.z = r * Math.sin(t)

			ring.add(dust)
		}
	}

		return ring
}