// Packages
import * as THREE from 'three'

// Functions
import ringGenerator from './ringGenerator'

// Data
import {
	frontFace, leftFace, rightFace, topFace, bottomFace, backFace, deepFace
} from './planetData'

// Colors
const purple = 0x3e01ca
const lightPurple = 0x4d00ff

// Materials
const purpleMaterial = new THREE.MeshBasicMaterial( {color: purple, side: THREE.DoubleSide} )
const lightMaterial = new THREE.MeshBasicMaterial( {color: lightPurple, side: THREE.DoubleSide} )

// Geometry
const planeGeometry = new THREE.PlaneGeometry(1, 1)
const purplePlane = new THREE.Mesh( planeGeometry, purpleMaterial )
const lightPlane = new THREE.Mesh( planeGeometry, lightMaterial )

/**
 * Planet Generator
 * 
 * Generate the planet
 */
export default function planetGenerator() {
	// Planet !
	const planet = new THREE.Group()
	
	planet.add(planetSideGenerator(frontFace, "front"))
	planet.add(planetSideGenerator(leftFace, "left"))
	planet.add(planetSideGenerator(rightFace, "right"))
	planet.add(planetSideGenerator(topFace, "top"))
	planet.add(planetSideGenerator(bottomFace, "bottom"))
	planet.add(planetSideGenerator(backFace, "back"))

	// Rings
	planet.add(ringGenerator(17, 23, 700))

	return planet
}

/**
 * Planet Side Generator
 * This function generate a planet side with plane
 * 
 * @param {array} faceArray array of the planet face
 * @param {string} face side of the planet face
 */
function planetSideGenerator(faceArray, face) {
	var side = new THREE.Group()
	const angle = Math.PI / 2

	for (let x = 0; x < 16; ++x) {
		for (let y = 0; y < 16; ++y) {
			switch (faceArray[x][y]) {
				case 1:
					var pixel = purplePlane.clone()

					pixel.position.set(x - 8, -y + 8, deepFace[x][y])
					side.add(pixel)
					break;

				case 2:
					var pixel = lightPlane.clone()

					// Front
					pixel.position.set(x - 8, -y + 8, deepFace[x][y])
					side.add(pixel)
					break;
			}
		}
	}

	// Rotation
	switch (face) {
		case "left":
			side.rotation.y = -angle
			side.position.x = -0.5
			side.position.z = 0.5
			break;

		case "right":
			side.rotation.y = angle
			side.position.x = -0.5
			side.position.z = -0.5
			break;

		case "top":
			side.rotation.x = -angle
			side.position.y = 0.5
			side.position.z = 0.5
			break;

		case "bottom":
			side.rotation.x = angle
			side.position.y = 0.5
			side.position.z = -0.5
			break;

		case "back":
			side.rotation.y = angle * 2
			side.position.x = -1
			break;
	}

	return side
}