// Packages
import * as THREE from 'three'
import $ from 'jquery'

// Functions
import planetGenerator from './planetGenerator'
import starsGenerator from './starsGenerator'

export default function planet() {
	// Scene
	const scene = new THREE.Scene();
	scene.background = new THREE.Color( 0x1a1d20 )

	const camera = new THREE.PerspectiveCamera( 70, 2, 2, 1000 );
	camera.position.z = 50;

	// Renderer
	const renderer = new THREE.WebGLRenderer({ canvas: document.querySelector("#planet canvas")});

	// Planet !
	const planet = planetGenerator();

	// Stars
	scene.add(starsGenerator())

	// Scene settings
	planet.rotation.y = 0.5
	planet.rotation.z = 0.4
	scene.add(planet);

	// Animation
	const axis = new THREE.Vector3(0, 10, 0).normalize();
	const speed = -0.0001;

	var animate = function () {
		resizeCanvasToDisplaySize();

		renderer.render( scene, camera );
		planet.rotateOnAxis(axis, speed);

		requestAnimationFrame(animate);
	};

	requestAnimationFrame(animate);

	function resizeCanvasToDisplaySize() {
		const canvas = renderer.domElement;
		const width = canvas.clientWidth;
		const height = canvas.clientHeight;
		if (canvas.width !== width ||canvas.height !== height) {
			// you must pass false here or three.js sadly fights the browser
			renderer.setSize(width, height, false);
			camera.aspect = width / height;
			camera.updateProjectionMatrix();

			// set render target sizes here
		}
	}

		/* DRAG */
	let isDragging = false;
	let previousMousePosition = {
		x: 0,
		y: 0
	};
	$(renderer.domElement).on('mousedown', function(e) {
		isDragging = true;
	})
	.on('mousemove', function(e) {
		//console.log(e);
		var deltaMove = {
			x: e.offsetX-previousMousePosition.x,
			y: e.offsetY-previousMousePosition.y
		};

		if(isDragging) {

			var deltaRotationQuaternion = new THREE.Quaternion()
				.setFromEuler(new THREE.Euler(
					toRadians(deltaMove.y * 1),
					toRadians(deltaMove.x * 1),
					0,
					'XYZ'
				));

			planet.quaternion.multiplyQuaternions(deltaRotationQuaternion, planet.quaternion);
		}

		previousMousePosition = {
			x: e.offsetX,
			y: e.offsetY
		};
	});
	/* */

	$(document).on('mouseup', function(e) {
		isDragging = false;
	});

	function toRadians(angle) {
		return angle * (Math.PI / 180);
	}

	function toDegrees(angle) {
		return angle * (180 / Math.PI);
	}
}